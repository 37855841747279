import React from "react";
import Content from '../views/Fleet/Features';

export default ({location}) => (
  <Content location={location} seo={{
    title: "A complete fleet management solution for your vehicles",
    lang: 'en',
    description: 'Fleet is a complete solution to manage all vehicle-related tasks within your company. Find what features & possibilities you get from fleet management service.',
    meta: [{
        name: 'keywords',
        content: 'Fleet management'
      }]
  }}/>
);